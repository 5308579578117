var delayInMilliseconds = 1000; //1 second
setTimeout(function() {
    const logo_top = document.querySelector(".home-fade-in  .logo-top");
    logo_top.classList.add("fade-in");
}, delayInMilliseconds);

const second_logo = 2000; //1 second
setTimeout(function() {
    const logo_bottom = document.querySelector(".home-fade-in .logo-bottom");
    logo_bottom.classList.add("fade-in");
}, second_logo);

jQuery(document).ready(function ($) {

    $('.hamburger').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('nav-close, is-active');
        $('body').toggleClass('nav-open');
    });

    if ($('.scroll-link').length) {
        $('.scroll-link a').on('click', function (e) {
            e.preventDefault();

            var target = $(this).attr('href');
            $('html, body').animate({
                'scrollTop': $(target).offset().top - 50
            }, 1000);

        });
    }

    if ($(window).width() < 992) {

        jQuery('.sh__content .sub-menu').prepend('<li class="menu-back"><a href="#"><b>< Back</b></a></li>');
    }

    jQuery('.header-main-nav .menu-item-has-children').prepend('<span><i class="far fa-angle-right"></i></span>');

        $('.menu-item-has-children span').on('click', function (e) {
            e.preventDefault();
            $(this).siblings('.sub-menu').addClass('in');
        });

        /* $('.menu-item-has-children > a').on('click', function (e) {
            e.preventDefault();
            $(this).siblings('.sub-menu').addClass('in');
        }); */

        $('.back a').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.sub-menu').removeClass('in');
        });

        $('.menu-back').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.sub-menu').removeClass('in');
        });




    if ($(window).width() > 767) {
        var $sticky = jQuery('.sticky');
        var $stickyrStopper = jQuery('footer');

        if (!!$sticky.offset()) { // make sure ".sticky" element exists

            var generalSidebarHeight = $sticky.innerHeight();
            var stickyTop = $sticky.offset().top;
            var stickOffset = 70;
            var stickyStopperPosition = $stickyrStopper.offset().top;
            var stopPoint = stickyStopperPosition - generalSidebarHeight - stickOffset;
            var diff = stopPoint + stickOffset;

            $(window).scroll(function () { // scroll event
                var windowTop = $(window).scrollTop(); // returns number
                console.log('windowTop is ' + windowTop);
                console.log('stopPoint is ' + stopPoint);

                if (stopPoint < windowTop) {
                    $sticky.css({position: 'sticky', top: '20px', height: '100%'});
                }
            });

        }
    }

// Smooth anchor scroll
    jQuery('a[href*="#"]:not([href="#"])').click(function () {
        var offset = -50; // <-- change the value here
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top + offset
                }, 1000);
                return false;
            }
        }
    });
});

var stickyTop = jQuery('.sh__content').offset().top - 0;
jQuery(window).on( 'scroll', function(){
    if (jQuery(window).scrollTop() >= stickyTop) {
        jQuery('.sh__content').css({position: "fixed", top: "0px"}).addClass('header-fixed');
        jQuery('.post-header').addClass('post-header--scrolled');

        jQuery('.height-block').addClass('scrolled');


    } else {
        jQuery('.sh__content').css({position: "relative", top: "0px"}).removeClass('header-fixed');
        jQuery('.post-header').removeClass('post-header--scrolled');
        jQuery('.height-block').removeClass('scrolled');
    }
});










